@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown ul {
    @apply list-disc list-inside;
}

.markdown pre code {
    @apply block w-full p-3 mt-3;
}

.markdown code {
    @apply p-1 rounded bg-slate-700;
}

.markdown p {
    @apply mt-3;
}

body, html {
    height: 100%;
}